body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Arabic */

.ui.grid .column.arabic,
.ui.popup > .header.arabic,
.ui.table tr td.arabic,
.ui.table tr td div.arabic,
.field .ui.input input.arabic,
.field .ui.input .field.arabic textarea,
.association-dropdown .ui.search.selection.dropdown.arabic input,
.association-dropdown .ui.search.selection.dropdown.arabic .text,
.association-dropdown .ui.search.selection.dropdown.arabic .item .text {
  direction: rtl;
  font-family: sans-serif;
  font-size: 18px;
  font-weight: normal;
  text-align: right;
  unicode-bidi: bidi-override;
}

.association-dropdown .ui.search.selection.dropdown.arabic input,
.field .ui.input input.arabic {
  line-height: 1.7;
  padding-top: .2em;
  padding-bottom: .2em;
}

.association-dropdown .ui.search.selection.dropdown.arabic .text {
  width: 100%;
}

.modals.dimmer.visible.active{
  overflow: auto;
}

.ui.modal.wide-modal {
  width: 1080px;
}

/* Offset the toaster component by 250px to account for the left side menu */
.content-container .ui.toaster {
  left: 250px;
}