.accordion-item-grid .middle.aligned.wide.column {
    overflow-x: hidden;
    text-overflow: ellipsis;
    max-width: 100ch;
}

.accordion-item-grid {
    width: 100%;
}

.accordion-item-grid .item-label{
    text-transform: uppercase;
    font-size: 11px;
    margin-bottom: 3px;
    color: rgb(175, 173, 173);
}