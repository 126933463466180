.genre .ui.secondary.pointing.menu .header.item {
   padding-bottom: 1rem;
   padding-left: 0;
 }

.genre .expand.ui.compact.icon.button {
  box-shadow: none;
}

.genre .ui.celled.table tr td:first-child.expand-container {
  width: 1px;
}