.root-container .ui.vertical.menu .menu {
  margin-top: 20px;
}

.root-container .ui.vertical.menu {
  width: 250px;
}

.root-container.ui.stretched.grid > .content-container.column {
  margin-left: 250px;
}

.ui.menu.main-menu {
  margin: 0;
}